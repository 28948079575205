.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (display-mode: standalone) {
.bottomNav {
  padding-bottom: 25px;
}
}



  .bottomRaceSelector {
    padding-bottom: 25px;
  }
  .accordionPanel, .myList{
    top:0px !important;
  }
  .appBar {
    z-index:5;
    margin-bottom: -35px;
  }
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .accordionPanel .MuiAccordionDetails-root {
    max-height: calc(100vh - 222px) !important;
    }
  
    @media (display-mode: fullscreen) {
      .accordionPanel, .myList{
        padding-top:350px;
        top:0px !important;
        margin-bottom: 350px !important;
      }
    }


  @media (display-mode: browser) {
    .bottomRaceSelector {
      padding-bottom: 0px;
    }
    .accordionPanel, .myList{
      padding-top:0px;
      top:0px !important;
      margin-bottom: 0px !important;
    }
    .appBar {
      z-index:5;
      margin-bottom: 0px;
    }
  
    .accordionPanel .MuiAccordionDetails-root {
      max-height: calc(100vh - 200px) !important;
    }
  }

  .appBar {
box-shadow: none !important;
padding-top: 40px;

  }

  
  @media (display-mode: standalone) {
    .headerNavBar {
      top: 40px !important;
        }
    .MuiDrawer-paper {
      padding-top: 40px;
    }
  }


  @media (display-mode: standalone) {
    .accordionPanel {
      max-height: calc(100vh - 66px) !important;
        }
    }

  @media (display-mode: standalone) {
    .accordionPanel {
      max-height: calc(100vh - 66px) !important;
        }
    }

    @media (display-mode: browser) {
          .appBar {
            padding-top: 0px;
          }
      }

@media only screen and (hover: none) and (pointer: coarse) and (display-mode: browser) {
  .accordionPanel {
    max-height: calc(100vh - 120px) !important;
      }
  }

  .baseAccordion {
    background-color: black !important;
    max-height: calc(100vh - 56px) !important;
    position: sticky !important;
    top: -1px !important;
    z-index: 3;
    width: 100% !important;
    height: 67px !important;
    box-sizing: border-box;
  }

  .whatsHotBar:nth-child(odd){
    background-color: #f4f4f4
  }    

